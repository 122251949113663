import { React, useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { Backdrop, Box, Button, CircularProgress, Link, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function Forgot() {
  const { t, data, forgot } = useMyData();

  const [idProps, resetId] = useInput(""); // ログインID

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [authStatus, setAuthStatus] = useState(""); // 認証の戻り値

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(idProps.value==="") err=true;

    setHasError(err);
  });


  // メール送信
  const submit = (e) => {
    e.preventDefault();
    setAuthStatus("WAITING");
    forgot(idProps.value, setAuthStatus);
  }


  if (data.loading) {
    return (
      <>
      <Backdrop style={{zIndex:1000,backgroundColor:'#fff'}} transitionDuration={0} open={true}>
        <CircularProgress />
      </Backdrop>
      </>
    );
  } else {
    return (
        <>
          <Typography component='h2' variant='h4' align='center'>
          {t('パスワード再設定')}
          </Typography>

          <TextField id="outlined-basic" label={t('メールアドレス')} variant="outlined" type="email" {...idProps} sx={{ mt: 3 }} />

          <Typography sx={{ mt: 3 }}>
          { authStatus==='WAITING' && <>{t('送信中')}</> }
          { authStatus==='OK' && <>{t('送信しました')}</> }
          { authStatus==='NG' && <>{t('送信失敗しました')}</> }
          </Typography>

          <Button variant="contained" disabled={ hasError } onClick={submit} sx={{ mt: 3 }} >{t('メール送信')}</Button>
        </>
    );
  }
}
