import { React, useState, useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import { Backdrop, Box, Button, CircularProgress, Link, TextField, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import GrassIcon from '@mui/icons-material/Grass';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

export default function TopAuth() {
  const { t, data, myAuth } = useMyData();

  const [idProps, resetId] = useInput(""); // ログインID
  const [passwordProps, resetPassword] = useInput(""); // パスワード

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [authStatus, setAuthStatus] = useState(""); // 認証の戻り値

  // エラーチェック
  useEffect(() => {
    let err = false;

    if (idProps.value === "") err = true;
    if (passwordProps.value === "") err = true;

    setHasError(err);
  });


  // ログイン
  const submit = (e) => {
    e.preventDefault();
    setAuthStatus("WAITING");
    myAuth(idProps.value, passwordProps.value, setAuthStatus);
  }


  if (data.loading) {
    return (
      <>
        <Backdrop style={{ zIndex: 1000, backgroundColor: '#fff' }} transitionDuration={0} open={true}>
          <CircularProgress />
        </Backdrop>
      </>
    );
  } else {
    return (


      <>

        <Typography component='h2' variant='h4' align='center'>
          {t('ログイン')}
        </Typography>

        <TextField id="outlined-basic" label={t('メールアドレス')} variant="outlined" type="email" autoComplete="email" {...idProps} sx={{ mt: 3 }} />
        <TextField id="outlined-password-input" label={t('パスワード')} type="password" autoComplete="current-password" {...passwordProps} sx={{ mt: 3 }} />

        <Typography sx={{ mt: 3 }}>
          {authStatus === 'WAITING' && <>{t('認証中')}</>}
          {authStatus === 'NO USER' && <><span className="notice">{t('IDまたはパスワードが正しくありません。')}</span></>}
          {authStatus === 'PASSWORD ERROR' && <><span className="notice">{t('IDまたはパスワードが正しくありません。')}</span></>}
          {authStatus === 'TOO MATCH FAILS' && <><span className="notice">{t('IDまたはパスワードが正しくありません。')}</span></>}
        </Typography>


        <Link
          href="/forgot"
          color="inherit"
          underline="hover"
          sx={{
            display: 'flex',
          }}
        >
          <ArrowRightIcon />{t('パスワードを忘れた方はこちら（再設定）')}
        </Link>

        <Button variant="contained" disabled={hasError} onClick={submit} sx={{ mt: 3 }} >{t('ログイン')}</Button>

      </>
    );
  }
}
