import { React, useState, useEffect } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import './Main.scss';
import { Box, Button, Container, Grid, Paper, TextField, Toolbar, Typography } from "@mui/material";
import Copyright from "./Copyright";

export default function Password({ menu = "" }) {
  let {forgot, uuid, mail} = useParams();

  const { updatePasswd } = useMyData();

  const [password1Props, resetPassword1] = useInput(""); // パスワード
  const [password2Props, resetPassword2] = useInput(""); // パスワード

  const [hasError, setHasError] = useState(true); // エラーチェック
  const [authStatus, setAuthStatus] = useState(""); // 認証の戻り値

  // エラーチェック
  useEffect(() => {
    let err = false;

    if(password1Props.value==="") err=true;
    if(password2Props.value==="") err=true;
    if(password1Props.value.length < 6) err=true;
    if(password1Props.value!==password2Props.value) err=true;

    setHasError(err);
  });

    
  // 更新
  const submit = (e) => {
    e.preventDefault();
    setAuthStatus("WAITING");
    updatePasswd(password1Props.value, uuid, forgot, mail, setAuthStatus);
  }

  return (
    <>
          <Typography component='h2' variant='h4' align='center'>
            パスワード再設定
          </Typography>

          
          <TextField id="outlined-password-input" label="新しいパスワード" type="password" autoComplete="new-password" {...password1Props} sx={{ mt: 3 }} />
          {password1Props.value.length < 6 && <Typography sx={{ mt: 3 }}>パスワードは6文字以上に設定してください</Typography>}
          <TextField id="outlined-password-input" label="新しいパスワード（確認用）" type="password" autoComplete="new-password" {...password2Props} sx={{ mt: 3 }} />
          {password1Props.value!==password2Props.value && <Typography sx={{ mt: 3 }}>パスワードが一致しません</Typography>}

          <Typography sx={{ mt: 3 }}>
            { authStatus==='WAITING' && <>設定中</> }
            { authStatus==='OK' && <>パスワードを再設定しました。</> }
            { authStatus==='NG' && <>パスワード再設定ができませんでした。</> }
          </Typography>

          <Button variant="contained" disabled={ hasError } onClick={submit} sx={{ mt: 3 }} >更新</Button>
    </>
  );
}
