import { React, useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Paper, CircularProgress, Grid, Box, Typography, FormControl, Stack, Snackbar, InputAdornment, OutlinedInput, InputLabel, Input, ListItemText, List, ListSubheader, ListItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Alert, Chip } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { useInput } from "./hooks";
import { useMyData } from "./MyProvider";
//import Grid from '@mui/material/Unstable_Grid2';
import FileDropzone from "./FileDropzone";


import { format, add, addMonths, getMonth } from 'date-fns'
import ItemRequestDialog from "./ItemRequestDialog";

export default function UserList({ data }) {
  let { role } = useParams();
  const { getUserList } = useMyData();


  const [loading, setLoading] = useState(true);
  const [localData, setLocalData] = useState([]);

  useLayoutEffect(() => {
    getUserList({ role: role }, setDataAndLoading);
  }, []);

  const setDataAndLoading = (data) => {
    setLoading(false);
    setLocalData(data);

  }



  return (
    <>
      {loading ?
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 20,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack sx={{ alignItems: 'center' }}>
              <CircularProgress />
            </Stack>
          </Paper>
        </Grid>
        :
        <Grid item xs={12}>
          <Typography component='h2' variant='h4' align='center'>
            {role == 1 && '受注者一覧'}
            {role == 2 && '発注者一覧'}
            {role == 3 && '管理者一覧'}
            登録
          </Typography>

          <Paper
            sx={{
              mt: 3,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >

            {localData.map((row) => (
              <TableContainer component={Paper} sx={{ mt: 1 }} key={row.v4}>
                <Table size="small" style={{ tableLayout: "fixed" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={2}><Button variant="outlined" href={"/user/" + row.v4}>{row.display_name}</Button></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ width: "30%" }}>email</TableCell>
                      <TableCell>{row.email_address}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ width: "30%" }}>住所</TableCell>
                      <TableCell>{row.zip ? "〒" + row.zip + " " : ""}{row.address}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell component="th" scope="row" style={{ width: "30%" }}>電話番号</TableCell>
                      <TableCell>{row.phone}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            ))}


          </Paper >
        </Grid >
      }
    </>
  );
}
