import { React, useState, useEffect } from "react";
import { Outlet, useLocation,Link } from "react-router-dom";
import { useMyData } from './MyProvider';
import { useInput } from "./hooks";
import './Main.scss';
import { Grid, Paper } from "@mui/material";

export default function Main({menu=""}) {
  const { data,signOut } = useMyData();



  return (
    <>
      <Grid item xs={12} md={8} lg={9}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          
        </Paper>
      </Grid>
      {/* Recent Deposits */}
      <Grid item xs={12} md={4} lg={3}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: 240,
          }}
        >
          
        </Paper>
      </Grid>
      {/* Recent Orders */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          
        </Paper>
      </Grid>
    </>
 );
}
