import { React } from "react";
import { Grid, Paper } from "@mui/material";
import Forgot from "./Forgot";

export default function Forgot0() {
    return (
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Forgot />
          </Paper>
      </Grid>
    );
}
