import { React, useEffect, useState } from "react";
import Header from "./Header";

import { useMyData } from './MyProvider';
import TopAuth from "./TopAuth";
import './App.scss';
import Main from "./Main";
//import useLocationChange from "./useLocationChange";
import { Routes, Route, Navigate, NavLink, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Container, createTheme, CssBaseline, Grid, Paper, Stack, ThemeProvider, Toolbar, Typography } from "@mui/material";
import GrassIcon from '@mui/icons-material/Grass';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ja from 'date-fns/locale/ja'

import Password from "./Password";
import Copyright from "./Copyright";
import Forgot from "./Forgot";
import Forgot0 from "./Forgot0";
import Password0 from "./Password0";
import Profile from "./Profile";
import ItemRequest from "./ItemRequest";
import ItemRequestList from "./ItemRequestList";
import User from "./User";
import ItemRequestData from "./ItemRequestData";
import UserAdd from "./UserAdd";
import UserList from "./UserList";

const theme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
  palette: {
    primary: {
      main: '#11966a',
    },
    subcolor: {
      main: '#BDECEF',
      contrastText: '#2F3D4A',
    },
    accent: {
      main: '#F88B5E',
      contrastText: '#fff',
    },
    outlinedbutton: {
      main: '#2F3D4A',
      contrastText: '#fff',
    },
    buttoncolor: {
      main: '#DBE2E8',
      contrastText: '#2F3D4A',
    },
    monobuttoncolor: {
      main: '#707070',
      contrastText: '#707070',
    },

  },
});


function App() {
  const { data, signOut, authCheck } = useMyData();


  //useLocationChange((location) => {
  //  authCheck();
  //});

  const location = useLocation();
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // execute on location change
    setCount(count + 1);
    authCheck(setLoading);
    //console.log('Location changed!', location.pathname);
    if (location.pathname === '/logout') {
      signOut();
    }
  }, [location]);



  if (loading) {
    return (
      <ThemeProvider theme={theme}>
        <Grid item xs={12}>
            <Stack sx={{ alignItems: 'center' }} mt={30}>
              <CircularProgress />
            </Stack>
        </Grid>
      </ThemeProvider>
    );
  } else if (data.token && !data.loading) {
    return (
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />

          <Header />

          <Box
            component="main"
            sx={{
              backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                  ? theme.palette.grey[100]
                  : theme.palette.grey[900],
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
          >
            <Toolbar />

            <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
              <Grid container spacing={3}>
                <Routes>
                  <Route path="/password/:forgot/:uuid/:mail" element={<Password0 />} />
                  <Route path="/forgot" element={<Forgot0 />} />
                  <Route path="/profile" element={<Profile data={data} />} />
                  <Route path="/user/:uuid" element={<User/>} />
                  <Route path="/itemRequest" element={<ItemRequest data={data} />} />
                  <Route path="/itemRequest/:uuid" element={<ItemRequestData data={data} />} />
                  <Route path="/itemRequestList" element={<ItemRequestList data={data} />} />
                  <Route path="/userAdd/:role" element={<UserAdd data={data} />} />
                  <Route path="/userList/:role" element={<UserList data={data} />} />
                  <Route path="*" element={<Main />} />
                </Routes>
              </Grid>
              <Copyright sx={{ pt: 4 }} />
            </Container>
          </Box>
        </Box>
        </LocalizationProvider>
      </ThemeProvider>
    );
  } else {
    return (
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: "column",
            justifyContent: "center",
            height: '100dvh',
            m: 0,
            p: 3,
            mx: 'auto',
          }}
        >


          <Box
            sx={{
              display: 'flex',
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "90vw", sm: "400px" },
              bgcolor: '#fff',
              mx: 'auto',
            }}
          >
            <GrassIcon sx={{ mr: 1 }} />
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              YaoYoroZoo
            </Typography>
          </Box>


          <Box
            sx={{
              display: 'flex',
              flexDirection: "column",
              //boxShadow: 1,
              width: { xs: "90vw", sm: "400px" },
              bgcolor: '#fff',
              m: 3,
              p: 3,
              mx: 'auto',
              borderRadius: 2,
              border: 1,
              borderColor: '#ccc',
            }}
          >

            <Routes>
              <Route path="/password/:forgot/:uuid/:mail" element={<Password />} />
              <Route path="/forgot" element={<Forgot />} />
              <Route path="*" element={<TopAuth />} />
            </Routes>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: { xs: "90vw", sm: "400px" },
              mx: 'auto',
            }}
          >

            <Typography variant="body2" color="text.secondary" align="center">
              ver. {process.env.REACT_APP_VERSION}
            </Typography>

          </Box>

        </Box>
      </ThemeProvider>
    );
  }
}

export default App;
