import { React, useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Paper, TextField, Grid, Box, Typography, FormControl, Stack, Snackbar, InputAdornment, OutlinedInput, InputLabel, Input, ListItemText, List, ListSubheader, ListItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Alert, Chip } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { useInput } from "./hooks";
import { useMyData } from "./MyProvider";
//import Grid from '@mui/material/Unstable_Grid2';
import FileDropzone from "./FileDropzone";


import { format, add, addMonths, getMonth } from 'date-fns'
import ItemRequestDialog from "./ItemRequestDialog";

export default function UserAdd() {
  let { role } = useParams();

  const { zip2addr, emailCheck, addUserData, postAvatorFile, setAvator } = useMyData();

  const [localData, setLocalData] = useState({});

  const [nameProps, resetName, setName] = useInput("","",(e) => { return e == "" });
  const [emailProps, resetEmail, setEmail,errEmail,errEmailMsg] = useInput("","",(e) => { return e == "" });
  const [zipProps, resetZip, setZip] = useInput("","number");
  const [addressProps, resetAddress, setAddress] = useInput("");
  const [phoneProps, resetPhone, setPhone] = useInput("");
  const [commentProps, resetComment, setComment] = useInput("");
  const [avatorProps, resetAvator, setLocalAvator] = useInput("");
  const [v4, setV4] = useState("");
  const [open, setOpen] = useState(false);

  const [hasError, setHasError] = useState(true); // エラーチェック

  const [prevEmail, setPrevEmail] = useState("");

  // エラーチェック
  useEffect(() => {
    let err = false;

    if (nameProps.value === "") err = true;
    if (emailProps.value === "") err = true;
    if (emailProps.error) err = true;

    setHasError(err);
  });

  useEffect(() => {
    //setMailError(true);
    if(emailProps.value != ""){
      let regex = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
      if(regex.test(emailProps.value)) {
        errEmailMsg("");
        if(prevEmail == emailProps.value) {
        } else {
          setPrevEmail ( emailProps.value );
        }
        emailCheck({email: emailProps.value}, setMailError)
      } else {
        setHasError(true);
        errEmail(true);
        errEmailMsg("メールアドレスの書式が間違っています");
      }
    } else {
      errEmailMsg("");
    }
    
  },[emailProps.value]);

  const setMailError = (ret) => {
    if(ret) {
      setHasError(false);
      errEmail(false);
      errEmailMsg("");
    } else {
      setHasError(true);
      errEmail(true);
      errEmailMsg("同じメールアドレスで、すでに登録されています");
    }


  }

  const submit = (e) => {

    addUserData({
      role: role,
      display_name: nameProps.value,
      zip: zipProps.value,
      email: emailProps.value,
      address: addressProps.value,
      phone: phoneProps.value,
    }, submitted);

  }

  const submitted = (ret) => {
    setOpen(ret);
    emailCheck({email: emailProps.value}, setMailError)
  }

  const setUploadedFile = (file) => {
    setLocalAvator(file.id);
  }

  const uploadFile = (file) => {
    postAvatorFile(file,setUploadedFile);
  }


  const handleClose = (event, reason) => {
    setOpen(false);
  };




  const zipChange = (e) => {

    let v = e.target.value;
    v = v.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g, '');
    setZip(v);

    if(v.length==7) {
      zip2addr(setAddress,v);
    }
  }

  return (
    <Grid item xs={12}>
      <Typography component='h2' variant='h4' align='center'>
        {role == 1 && '受注者'}
        {role == 2 && '発注者'}
        {role == 3 && '管理者'}
        登録
      </Typography>

      <Paper
        sx={{
          mt: 3,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >


        <TextField label="表示名" type="text" {...nameProps} autoComplete="name" sx={{ mt: 3 }} />

        <TextField label="email" type="email" {...emailProps} autoComplete="email" sx={{ mt: 3 }} />

        <TextField label="〒" type="text" autoComplete="postal-code" inputProps={{ maxLength: 7 }} {...zipProps} onChange={zipChange} sx={{ width: 220, mt: 3 }} />

        <TextField label="住所" type="text" {...addressProps} sx={{ mt: 3 }} />

        <TextField label="電話番号" type="text" autoComplete="tel-national" {...phoneProps} sx={{ mt: 3 }} />


        <Button variant="contained" disabled={hasError} onClick={submit} sx={{ mt: 3 }} >登録</Button>

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          onClose={handleClose}
          autoHideDuration={6000}
          message="登録しました。"
        />

      </Paper >
    </Grid >
  );
}
