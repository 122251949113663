import { React } from "react";
import { Button, TextField,  Dialog, DialogTitle, DialogContent, DialogActions, Grid  } from "@mui/material";


export default function ItemRequestDialog({dataProps={}, title="",open=true,handleClose= f => f,handleSubmit= f => f,}) {

  return (
    <Dialog open={open} fullWidth onClose={handleClose}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <TextField
        label="コメント"
        fullWidth
        //style={{ minWidth: "400px" }}
        multiline
        type="text"

        {...dataProps} sx={{ mt: 3 }}
      />
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={handleClose}>キャンセル</Button>
      <Button variant="contained" onClick={handleSubmit}>{title}</Button>
    </DialogActions>
  </Dialog>
);
}
