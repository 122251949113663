import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
    }
  },
  ja: {
    translation: {
      "YaoYoroZoo": "YaoYoroZoo",

      "食材リクエストリスト": "食材リクエストリスト",
      "新規食材リクエスト": "新規食材リクエスト",
      "発注者リスト": "発注者リスト",
      "発注者登録": "発注者登録",
      "受注者リスト": "受注者リスト",
      "受注者登録": "受注者登録",
      "管理者リスト": "管理者リスト",
      "管理者登録": "管理者登録",

      "プロフィール編集": "プロフィール編集",
      "パスワード再設定": "パスワード再設定",
      "ダッシュボード": "ダッシュボード",
      "ログアウト": "ログアウト",

      "ログイン": "ログイン",
      "メールアドレス": "メールアドレス",
      "パスワード": "パスワード",
      "パスワード再設定": "パスワード再設定",
      "パスワードを忘れた方はこちら（再設定）": "パスワードを忘れた方はこちら（再設定）",
      "認証中":"認証中",
      "メール送信":"メール送信",
      "送信中":"送信中",
      "送信しました":"送信しました",
      "送信失敗しました":"送信失敗しました",
      "IDまたはパスワードが正しくありません。":"IDまたはパスワードが正しくありません。",
      "画像をこのエリアにドロップ":"画像をこのエリアにドロップ",
      "画像アップロード":"画像アップロード",
      "(ドラッグアンドドロップ または クリック)":"(ドラッグアンドドロップ または クリック)",
      "(タップして画像選択)":"(タップして画像選択)",
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "ja", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;