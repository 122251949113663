import { Typography } from "@mui/material";
import { React } from "react";
import { useMyData } from "./MyProvider";


export default function Copyright(props) {
  const { t } = useMyData();

  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {t('YaoYoroZoo')}
      {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
