import { React, useState, useEffect, useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Paper, TextField, Grid, Box, Typography, FormControl, Stack, Snackbar, InputAdornment, OutlinedInput, InputLabel, Input } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { useInput } from "./hooks";
import { useMyData } from "./MyProvider";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers";

//import Grid from '@mui/material/Unstable_Grid2';

import { format, add, addMonths, getMonth } from 'date-fns'

export default function ItemRequest() {

  const { t, updateItemRequest } = useMyData();
  const navigate = useNavigate();

  const today = new Date();
  const addday = add(today, { days: 7 });
  const today7 = format(addday, 'yyyy-MM-dd');
  const today0 = format(today, 'yyyy-MM-dd');



  const [nameProps, resetName, setName] = useInput("","",(e) => { return e == "" });
  const [volumeProps, resetVolume, setVolume] = useInput("", "number",(e) => { return e == "" });
  const [dateProps, resetDate, setDate] = useInput(today7,"date",(e) => { return e == "" || e < today0 });
  const [commentProps, resetComment, setComment] = useInput("");


  const [open, setOpen] = useState(false);

  const itemNames = [
    'トマト',
    'きゅうり',
    'にんじん',
    'じゃがいも',
    'たまねぎ',
    'ピーマン',
    'なす',
    '長ネギ',
    'ダイコン',
    'キャベツ',
    'レタス',
  ];


  const [hasError, setHasError] = useState(true); // エラーチェック
  const [confirmation, setConfirmation] = useState(false); // 確認
  const [created, setCreated] = useState(false);

  // エラーチェック
  useEffect(() => {
    let err = false;

    if (nameProps.value === "") err = true;
    if (volumeProps.value === "") err = true;
    if (dateProps.value === "") err = true;

    setHasError(err);
  });


  const confirm = () => {
    setConfirmation(true);
  }

  const back = () => {
    setConfirmation(false);
  }


  const submit = () => {
    updateItemRequest({
      name: nameProps.value,
      volume: volumeProps.value,
      duedate: dateProps.value,
      comment: commentProps.value,
    }, submitted);
  }


  const submitted = (ret) => {
    setOpen(ret);
    setCreated(true);
  }

  const handleClose = (event, reason) => {
    setOpen(false);
  };


  const finish = () => {
    navigate("/itemRequestList");
  }

  return (
    <Grid item xs={12}>
        <Typography component='h2' variant='h4' align='center'>
          {t('新規食材リクエスト')}
        </Typography>

      <Paper
        sx={{
          mt: 3,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        {confirmation ? 
          <>
          {/*  確認画面 */}
            <TextField label="食材名" focused={true} variant="standard" InputProps={{ readOnly: true }} type="text" {...nameProps} sx={{ mt: 3 }} />

            <FormControl focused={true} sx={{ width: 220, mt: 3 }} variant="standard">
              <InputLabel htmlFor='request-volume'>必要量</InputLabel>
              <Input
                id="request-volume"
                label="必要量"
                readOnly
                {...volumeProps}
                endAdornment={<InputAdornment position="end">kg</InputAdornment>}
              />
            </FormControl>

            <TextField
              label="納期"
              focused={true}
              variant="standard"
              InputProps={{ readOnly: true }}
              defaultValue={today7}
              type="date"
              {...dateProps}
              sx={{ width: 220, mt: 3 }}
              InputLabelProps={{ shrink: true }}
            />

            <TextField minRows={4} label="コメント" focused={true} variant="standard" InputLabelProps={{ shrink: true }} InputProps={{ readOnly: true }} multiline {...commentProps} sx={{ mt: 3 }} />

            { created  ?
            <Button variant="outlined" disabled={hasError} onClick={finish} sx={{ mt: 3 }} fullWidth>一覧へ</Button>
              :
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button variant="outlined" disabled={hasError} onClick={back} sx={{ mt: 3 }} fullWidth>修正</Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" disabled={hasError} onClick={submit} sx={{ mt: 3 }} fullWidth>登録</Button>
              </Grid>
            </Grid>
            }

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              onClose={handleClose}
              autoHideDuration={6000}
              message="登録しました。"
            />

          </>
          :
          <>
          {/*  入力画面 */}
            <Autocomplete
              freeSolo
              options={itemNames}
              renderInput={(params) => <TextField id="request-name" label="食材名"  type="text" {...params} />}
              value={nameProps.value}
              onChange={(event, newValue) => {
                setName(newValue);
              }}
            />

            <FormControl sx={{ width: 220, mt: 3 }}>
              <InputLabel htmlFor='request-volume'>必要量</InputLabel>
              <OutlinedInput
                id="request-volume"
                label="必要量"
                type="number"
                {...volumeProps}
                endAdornment={<InputAdornment position="end">kg</InputAdornment>}
              />
            </FormControl>

            <TextField
              label="納期"
              type="date"
              {...dateProps}
              sx={{ width: 220, mt: 3 }}
              InputLabelProps={{ shrink: true , min: today0}}
            />

            <TextField minRows={4} label="コメント" multiline {...commentProps} sx={{ mt: 3 }} />

            <Button variant="contained" disabled={hasError} onClick={confirm} sx={{ mt: 3 }} >確認</Button>
            </>
}
        
      </Paper>
    </Grid>
  );
}
