import { React, useState, useEffect, useLayoutEffect } from "react";
import { Button, Grid, Paper, TextField, CircularProgress, Typography, Box, Stack, Snackbar, Alert, Card, CardMedia } from "@mui/material";
import { useInput } from "./hooks";
import { useMyData } from "./MyProvider";
import FileDropzone from "./FileDropzone";
import { useNavigate, useParams } from "react-router-dom";


export default function User() {
  let {uuid} = useParams();
  const { getUserData, nl2br, setAvator } = useMyData();

  const navigate = useNavigate();

  const [localData, setLocalData] = useState({});

  const [nameProps, resetName, setName] = useInput("");
  const [zipProps, resetZip, setZip] = useInput("");
  const [addressProps, resetAddress, setAddress] = useInput("");
  const [phoneProps, resetPhone, setPhone] = useInput("");
  const [commentProps, resetComment, setComment] = useInput("");
  const [avatorProps, resetAvator, setLocalAvator] = useInput("");
  const [v4, setV4] = useState("");
  const [loading, setLoading] = useState(true);



  useLayoutEffect(() => {
    getUserData(setDataAndLoading, uuid);
  }, []);

  const setDataAndLoading = (data) => {
    setLoading(false);
    setLocalData(data);

    setV4(data.v4);
    setName(data.display_name);
    setZip(data.zip);
    setAddress(data.address);
    setPhone(data.phone);
    setComment(data.comment);
    setLocalAvator(data.avator);


  }

  const back = () => {
    navigate(-1);
  }

  return (
    <>
      {loading ?
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 20,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack sx={{ alignItems: 'center' }}>
              <CircularProgress />
            </Stack>
          </Paper>
        </Grid>
        :
        <Grid item xs={12}>
            <Typography component='h2' variant='h4' align='center'>
              プロフィール
            </Typography>


          <Paper
            sx={{
              mt: 3,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {avatorProps.value &&
            <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
           >
            <Grid item xs={3} mb={3}>
            <Card sx={{ width:"100%" }}>
              <CardMedia
                component="img"
                alt={nameProps.value}
                height="345"
                image={`${process.env.REACT_APP_API_ENDPOINT}user/avator/${v4}/${avatorProps.value}`}
                sx={{ objectFit: "cover", width:"100%", maxWidth:345 }}
              />
            </Card>
            </Grid>
            </Grid>
            }

            <Typography gutterBottom variant="h5" component="div">
              {nameProps.value}
            </Typography>


            {addressProps.value != "" &&
            <TextField
              label="住所"
              variant="standard"
              focused={true}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true, disableUnderline: true }}
              type="text"
              multiline
              value={(zipProps.value != "" ? "〒" + zipProps.value + "\n " : "") + addressProps.value} sx={{ mt: 3 }}
            />
            }



            {phoneProps.value != "" &&
            <TextField
              label="電話番号"
              variant="standard"
              focused={true}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true, disableUnderline: true }}
              type="text"
              multiline
              value={phoneProps.value} sx={{ mt: 3 }}
            />
            }


            {commentProps.value != "" &&
            <TextField
              label="コメント"
              variant="standard"
              focused={true}
              InputLabelProps={{ shrink: true }}
              InputProps={{ readOnly: true, disableUnderline: true }}
              type="text"
              multiline
              value={commentProps.value} sx={{ mt: 3 }}
            />
            }


            <Button variant="contained" onClick={back} sx={{ mt: 3 }} >もどる</Button>


          </Paper>
        </Grid>
      }
    </>
  );
}
