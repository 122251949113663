import React, { createContext, useState, useContext } from "react";
import { parse, format, add, addMonths, getMonth } from 'date-fns'
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MyContext = createContext();
export const useMyData = () => useContext(MyContext);

export default function MyProvider({ children }) {
  const [data, setData] = useState({});
  const [param, setParam] = useState({});
  const [cparam, setCParam] = useState({});
  const [downloadlist, setDownloadlist] = useState([]);
  const { t, i18n } = useTranslation();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));

  const loadJSON = (key) => {
    if (key) {
    } else {
      return {};
    }
    const data = localStorage.getItem(key);
    if (typeof data === "undefined" || data === "undefined") {
      return {};
    } else {
      return JSON.parse(data)
    }
  };
  const saveJSON = (key, data) => localStorage.setItem(key, JSON.stringify(data));

  // TOKENが有効なのか確認
  const authCheck = async (target) => {
    const token = loadJSON("token");
    const loading = loadJSON("loading");
    if (loading == 1) {
      return;
    }
    saveJSON("loading", 1);

    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    setData({
      ...data,
      "loading": true,
    });
    try {
      const response = await fetch(
        API_ENDPOINT + 'authcheck',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const d = await response.json();
      saveJSON("loading", 0);
      if (d) {
        target(false);
        if (d.status) {
          if (d.status === "OK" && d.token) {
            saveJSON("token", d.token);
            setData({
              ...data,
              "loading": false,
              "token": d.token,
              "name": d.name,
              "v4": d.v4,
              "avator": d.avator,
              "post": d.post,
              "user": d.user,
              "role": d.role,
              "lastlogin": d.lastlogin,
            })
          } else {
            setData({
              ...data,
              "token": undefined,
              "name": undefined,
              "v4": undefined,
              "avator": undefined,
              "post": undefined,
              "user": undefined,
              "role": undefined,
              "lastlogin": undefined,
            })
          }
        } else {
          signOut(0);
        }
      } else {
        signOut(1);
      }
    } catch (error) {
      console.log(error);
      saveJSON("loading", 0);
    }
  }

  // APIを叩いてパスワード再設定
  const updatePasswd = async (password, uuid, forgot, mail, target) => {
    if(mail){
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'updatepw',
          {
            headers: {
              'Content-type':'application/json'
            },
            method: "post",
            body: JSON.stringify({ password, uuid, forgot, mail }),
            cache: "no-cache",
          }
        );
        const data = await response.json();
        if(data) {
          if ( data.status ) {
            target(data.status);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }


  // APIを叩いてパスワード忘れ
  const forgot = async (mail, target) => {
    if(mail){
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'forgot',
          {
            headers: {
              'Content-type':'application/json'
            },
            method: "post",
            body: JSON.stringify({ mail }),
            cache: "no-cache",
          }
        );
        const data = await response.json();
        if(data) {
          if ( data.status ) {
            target(data.status);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // APIを叩いてログイン
  const myAuth = async (id, pw, target) => {
    if (id && pw) {
      const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
      try {
        const response = await fetch(
          API_ENDPOINT + 'signin',
          {
            headers: {
              'Content-type': 'application/json'
            },
            method: "post",
            body: JSON.stringify({ id, pw }),
            cache: "no-cache",
          }
        );
        const data = await response.json();
        if (data) {
          if (data.status) {
            target(data.status);
          }
          if (data.token) {
            saveJSON("token", data.token);
            saveJSON("loading", 0);
            setData({
              ...data,
              "token": data.token,
            })
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  }

  // ログアウト
  const signOut = (redirect = 1) => {
    setData({
      ...data,
      "token": undefined,
      "name": undefined,
      "post": undefined,
      "user": undefined,
    });
    saveJSON("token", undefined);
    saveJSON("loading", 0);
    if (redirect) {
      window.location = "/";
    }
  }

  // MENUセット
  const setMenu = (menu) => {
    setData({
      ...data,
      menu,
    })
  }


  // ユーザーメールアドレス重複確認
  const emailCheck = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'user/email',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data.status === 'OK') {
        target(true);
      } else {
        target(false);
      }
    } catch (error) {
      console.log(error);
    }
  }


  // ユーザー情報取得
  const getUserData = async (target, v4="") => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'user/data' + (v4 != "" ? "/" + v4 : ""),
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // ユーザー情報更新
  const updateUserData = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'user/data',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data.status === 'OK') {
        target(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // ユーザー情報更新
  const getUserList = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'user/list',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data.status === 'OK') {
        target(data.list);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // ユーザー情報作成
  const addUserData = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'user/add',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data.status === 'OK') {
        target(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const postAvatorFile = async (file, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

    const formData = new FormData();
    formData.append('File', file);
    formData.append('filepath', file.path);

    try {
      const response = await fetch(
        API_ENDPOINT + 'user/avator',
        {
          headers: {
            'X-Token': token
          },
          method: "POST",
          cache: "no-cache",
          body: formData,
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          setHangler(d);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  const setAvator = (avator) => {
    setData({
      ...data,
      avator
    });
  }

  // 食材リクエスト登録&更新
  const updateItemRequest = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'item/data',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data.status === 'OK') {
        target(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 食材リクエスト一覧
  const getRequestList = async (target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'item/list',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 食材リクエスト情報取得
  const getRequestData = async (target, v4="") => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'item/data' + (v4 != "" ? "/" + v4 : ""),
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data) {
        target(data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  
  // 食材リクエストに応募
  const itemRequestReply = async (params, target) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'item/reply',
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "POST",
          body: JSON.stringify(params),
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data.status === 'OK') {
        target(true);
      }
    } catch (error) {
      console.log(error);
    }
  }

  




  const dateFormat = (d) => {
    if (d) {
      return d.format('YYYY-MM-DD');
    } else {
      return '';
    }
  }


  // Files To Zip
  const fileDownloadZip = async (v4s, setHangler) => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    setHangler(false);

    try {
      const response = await fetch(
        API_ENDPOINT + 'file/zip',
        {
          headers: {
            'X-Token': token
          },
          method: "POST",
          cache: "no-cache",
          body: JSON.stringify({ files: v4s }),
        }
      );
      const d = await response.json();
      if (d) {
        if (d.status === 'OK') {
          const downloadTag = document.createElement('a');
          downloadTag.href = API_ENDPOINT + 'file/' + d.v4 + '/download/' + token;
          downloadTag.download = 'data.zip';
          downloadTag.click();
          setHangler(true);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  // 郵便番号
  const zip2addr = async ( setAddr ,  zip = '') => {
    const token = loadJSON("token");
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    try {
      const response = await fetch(
        API_ENDPOINT + 'kenall/' + zip,
        {
          headers: {
            'Content-type': 'application/json',
            'X-Token': token
          },
          method: "GET",
          cache: "no-cache",
        }
      );
      const data = await response.json();
      if (data && data.addr1) {
        //setPref(data.addr1);
        setAddr(data.addr1 + data.addr2);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const formatDate = (date,toFmt='yyyy/MM/dd',fromFmt='yyyy-MM-dd') => {
    const dateObj = parse(date, fromFmt, new Date());
    return format(dateObj, toFmt);
  }

  const nl2br = (text) => {
    return <>{text.split(/(\n)/).map((v, i) => (i & 1 ? <br key={i} /> : v))}</>;
  }


  return (
    <MyContext.Provider value={{
      t,
      sm,
      loadJSON,
      data,
      param, setParam,
      cparam, setCParam,
      downloadlist, setDownloadlist,
      myAuth,
      forgot,
      updatePasswd,
      authCheck,
      signOut,

      emailCheck,
      getUserData,
      getUserList,
      addUserData,
      updateUserData,
      postAvatorFile,
      setAvator,
      updateItemRequest,
      getRequestList,
      getRequestData,
      itemRequestReply,

      setMenu,
      formatDate,
      nl2br,

      zip2addr,
    }}>
      {children}
    </MyContext.Provider>
  );
}
