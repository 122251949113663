import { React, useState } from "react";
import { useNavigate } from "react-router-dom";

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import GrassIcon from '@mui/icons-material/Grass';

import { useMyData } from './MyProvider';
import './Header.scss';


export default function Header(props) {
  const { t, data, signOut } = useMyData();

  const pages = {
    "0": [
    ],
    // 農家
    "1": [
      {name: t('食材リクエストリスト') , path:'/itemRequestList'},
      //{name:'受注一覧' , path:'/itemRequestList'},
    ],
    // 給食センター
    "2": [
      {name: t('新規食材リクエスト') , path:'/itemRequest'},
      {name: t('食材リクエストリスト') , path:'/itemRequestList'},
      //{name:'発注一覧' , path:'/itemRequestList'},
    ],
    // 管理者
    "3": [
      {name: t('食材リクエストリスト') , path:'/itemRequestList'},
      {name: t('発注者リスト') , path:'/userList/2/'},
      {name: t('発注者登録') , path:'/userAdd/2/'},
      {name: t('受注者リスト') , path:'/userList/1/'},
      {name: t('受注者登録') , path:'/userAdd/1/'},
      {name: t('管理者リスト') , path:'/userList/3/'},
      {name: t('管理者登録') , path:'/userAdd/3/'},
    ],
  };
  const settings = [
    {name: t('プロフィール編集') , path:'/profile'},
    {name: t('パスワード再設定') , path:'/forgot'}, 
    {name: t('ダッシュボード') , path:'/dashboard'}, 
    {name: t('ログアウト') , path:'/logout'}
  ];

  const navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  //useEffect(()=>{
  //  console.log(data);
  //},[]);

  const handleSelectMenu = (e) => {
    //console.log(e);

    if(e === '/logout') {
      signOut()
    } else {
      navigate(e);
    }

    setAnchorElUser(null);
  }


  // サインアウト
  const signout = (e) => {
    signOut()
  }

  return (
    <AppBar>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <GrassIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('YaoYoroZoo')}
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {data.role && pages[data.role].map((page) => (
                <MenuItem key={page.name} onClick={() => handleSelectMenu(page.path)}>
                  <Typography textAlign="center">{page.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <GrassIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
          <Typography
            variant="h5"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
            {t('YaoYoroZoo')}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {data.role && pages[data.role].map((page) => (
              <Button
                key={page.name}
                onClick={() => handleSelectMenu(page.path)}
                sx={{ my: 2, color: 'white', display: 'block' }}
              >
                {page.name}
              </Button>
            ))}
          </Box>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="設定">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt={data.name} src={`${process.env.REACT_APP_API_ENDPOINT}user/avator/${data.v4}/${data.avator}`} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={() => handleSelectMenu(setting.path)}>
                  <Typography textAlign="center">{setting.name}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );  

}
