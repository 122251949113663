import { useState, useEffect, useMemo, useCallback, useRef } from "react";
import { Location, useLocation } from 'react-router-dom'

export const useInput = (initalValue,mode="string",validation) => {
  const [value, setValue] = useState(initalValue);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  
  useEffect(() => {
    setError(validation ? validation(value) : false)
  },[value]);
  
  return [
    { value,
      onChange: e => {
        let v = e.target.value;
        if(mode==='number') {
          v = v.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g, '');
          setValue(v)
        } else {
          setValue(v)
        }
        setError(validation ? validation(v) : false)
      },
      //onKeyDown: e => {
      //  if(mode==='kana') {
      //    if (e.key === "Enter" && e.keyCode === 229) {
      //      let v = e.target.value;
      //      v = v.replace(/[ぁ-ゖ]/g, s => String.fromCharCode(s.charCodeAt(0) + 96)).replace(/[^ァ-ー]/g, '');
      //      setValue(v)
      //    }
      //  }
      //},
      onBlur: e => {
        if(mode==='kana') {
          let v = e.target.value;
          v = v.replace(/[ぁ-ゖ]/g, s => String.fromCharCode(s.charCodeAt(0) + 96)).replace(/[^ァ-ー]/g, '');
          setValue(v)
        }
      },
      error: error,
      helperText: errorMsg,
    },
    () => setValue(initalValue),
    (val) => setValue(val),
    (val) => {
      setError(val)
    },
    (val) => setErrorMsg(val),
  ];
}

export const useCheckbox = (initalValue) => {
  const [val, setValue] = useState(initalValue);
  return [
    { val,
      onChange: e => {
        if (val.includes(e.target.value)) {
          setValue(val.filter(item => item !== e.target.value));
        } else {
          setValue([...val, e.target.value]);
        } 
      },
    },
    () => setValue(initalValue),
    (val) => setValue(val),
  ];
}

export const useRadio = (initalValue) => {
  const [val, setValue] = useState(initalValue);
  return [
    { val,
      onChange: e => {
        setValue(e.target.value);
      }
    },
    () => setValue(initalValue),
    (val) => setValue(val),
  ];
}


export function useFetch(uri) {
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(true);

  const mounted = useMountedRef();

  useEffect(() => {
    if (!uri) return;
    if (!mounted.current) return;
    setLoading(true);
    fetch(uri)
      .then(data => {
        if (!mounted.current) throw new Error("component is not mounted");
        return data;
      })
      .then(data => data.json())
      .then(setData)
      .then(() => setLoading(false))
      .catch(error => {
        if (!mounted.current) return;
        setError(error)
      });
  }, [uri]);

  return {
    loading,
    data,
    error
  };
}

export const useIterator = (
  items = [],
  initalIndex = 0
) => {
  const [i, setIndex] = useState(initalIndex);

  const prev = useCallback(() => {
    if ( i === 0 ) return setIndex(items.length - 1);
    setIndex(i - 1);
  }, [i,items]);

  const next = useCallback(() => {
    if ( i === items.length - 1) return setIndex(0);
    setIndex( i + 1);
  }, [i,items]);

  const item = useMemo(() => items[i], [i,items]);

  return [item || items[0], prev, next];

};


export function useMountedRef() {
  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => (mounted.current = false);
  });
  return mounted;
}

export function useOutsideHandle(ref, handle = f => f) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handle();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}
