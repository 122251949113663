import { React, useState, useEffect, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Paper, TextField, Grid, Box, Typography, FormControl, Stack, Snackbar, InputAdornment, OutlinedInput, InputLabel, Input, ListItemText, List, ListSubheader, ListItem, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Alert, Chip } from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete';
import { useInput } from "./hooks";
import { useMyData } from "./MyProvider";
//import Grid from '@mui/material/Unstable_Grid2';

import { format, add, addMonths, getMonth } from 'date-fns'
import ItemRequestDialog from "./ItemRequestDialog";

export default function ItemRequestData() {
  let { uuid } = useParams();

  const navigate = useNavigate();

  const { sm, nl2br, data, getRequestData, formatDate, itemRequestReply } = useMyData();

  const today = new Date();
  const addday = add(today, { days: 7 });
  const today7 = format(addday, 'yyyy-MM-dd');

  const [loading, setLoading] = useState(true);


  const [nameProps, resetName, setName] = useInput("");
  const [volumeProps, resetVolume, setVolume] = useInput("", "number");
  const [dateProps, resetDate, setDate] = useInput(today7);
  const [commentProps, resetComment, setComment] = useInput("");
  const [centerCommentProps, resetCenterComment, setCenterComment] = useInput("");
  const [fixed, setFixed] = useState(0);


  const [msg, setMsg] = useState("応募しました。");

  const [open, setOpen] = useState(false);
  const [dopen, setDOpen] = useState(false);
  const [copen, setCOpen] = useState(false);
  const [aopen, setAOpen] = useState(false);
  const [ropen, setROpen] = useState(false);

  const [center, setCenter] = useState("");
  const [centerV4, setCenterV4] = useState("");

  const [replys, setReplys] = useState([]);

  const [dvolumeProps, resetDVolume, setDVolume] = useInput("", "number", (e) => { return e == "" || e > volumeProps.value - fixed });
  const [dpriceProps, resetDPrice, setDPrice] = useInput("", "number", (e) => { return e == "" });
  const [dcommentProps, resetDComment, setDComment] = useInput("");

  const [ccommentProps, resetCComment, setCComment] = useInput("");
  const [acommentProps, resetAComment, setAComment] = useInput("");
  const [rcommentProps, resetRComment, setRComment] = useInput("");

  const [targetV4, setTargetV4] = useState("");

  const [hasError, setHasError] = useState(true); // エラーチェック


  useLayoutEffect(() => {
    getRequestData(setDataAndLoading, uuid);
  }, []);

  const setDataAndLoading = (data) => {
    setLoading(false);

    console.log(data);

    if (data.name) {
      setName(data.name);
      setVolume(data.volume);
      setFixed(data.fixed);
      setDVolume(data.volume - data.fixed);
      setDate(data.duedate);
      setComment(data.comment);
      setCenterComment(data.center_comment);

      setCenter(data.center);
      setCenterV4(data.center_v4);

      setReplys(data.replys);
    } else {
      navigate("/itemRequestList");
    }

  }



  // エラーチェック
  useEffect(() => {
    let err = false;

    if (dvolumeProps.error) err = true;
    if (dpriceProps.error) err = true;

    setHasError(err);
  });


  const back = () => {
    navigate(-1);
  }


  const submit = () => {
    setDOpen(true);
  }


  const handleDSubmit = () => {
    itemRequestReply({
      v4: uuid,
      volume: dvolumeProps.value,
      price: dpriceProps.value,
      comment: dcommentProps.value,
      mode: "new",
    }, submitted);
    setMsg("応募しました");
    setDPrice("");
    setDComment("");
  }

  const submitted = (ret) => {
    getRequestData(setDataAndLoading, uuid);
    setDOpen(false);
    setOpen(true);
  }

  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const handleDClose = (event, reason) => {
    setDOpen(false);
  };

  const cancel = (v4) => {
    setTargetV4(v4);
    setCOpen(true);
  }
  const cancelClose = () => {
    setCOpen(false);
  }
  const cancelSubmit = () => {
    itemRequestReply({
      v4: targetV4,
      comment: ccommentProps.value,
      mode: "cancel",
    }, submitted);
    setCComment("");
    setMsg("取り消しました");
    setCOpen(false);
  }


  const apply = (v4) => {
    setTargetV4(v4);
    setAOpen(true);
  }
  const applyClose = () => {
    setAOpen(false);
  }
  const applySubmit = () => {
    itemRequestReply({
      v4: targetV4,
      comment: acommentProps.value,
      mode: "apply",
    }, submitted);
    setAComment("");
    setMsg("発注しました");
    setAOpen(false);
  }

  const reject = (v4) => {
    setTargetV4(v4);
    setROpen(true);
  }
  const rejectClose = () => {
    setROpen(false);
  }
  const rejectSubmit = () => {
    itemRequestReply({
      v4: targetV4,
      comment: rcommentProps.value,
      mode: "reject",
    }, submitted);
    setRComment("");
    setMsg("却下しました");
    setROpen(false);
  }



  return (
    <Grid item xs={12}>
      <Typography component='h2' variant='h4' align='center'>
        食材リクエスト
      </Typography>

      <Paper
        sx={{
          mt: 3,
          p: 2,
          display: 'flex',
          flexDirection: 'column',
        }}
      >

        <FormControl focused={true} sx={{ mt: 3 }}
          variant="standard"
        >
          <InputLabel sx={{ shrink: true }}>発注者</InputLabel>
          <Box mt={3}>
            <Button
              href={"/user/" + centerV4}
              variant="outlined"
              size="large"

            >{center}</Button>
          </Box>
        </FormControl>

        <TextField
          label="食材名"
          variant="standard"
          focused={true}
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: true, disableUnderline: true }}
          type="text"
          {...nameProps} sx={{ mt: 3 }}
        />


        <TextField
          label="必要量"
          variant="standard"
          focused={true}
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: true, disableUnderline: true }}
          type="text"
          value={volumeProps.value + "kg"} sx={{ mt: 3 }}
          helperText={fixed > 0 ? " ( " + fixed + "kg 発注済 のこり必要量 " + (volumeProps.value - fixed) + "kg )" : ""}
        />

        <TextField
          label="納期"
          variant="standard"
          focused={true}
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: true, disableUnderline: true }}
          type="text"
          value={formatDate(dateProps.value)} sx={{ mt: 3 }}
        />

        <TextField
          label="コメント"
          variant="standard"
          focused={true}
          multiline
          InputLabelProps={{ shrink: true }}
          InputProps={{ readOnly: true, disableUnderline: true }}
          type="text"
          value={commentProps.value} sx={{ mt: 3 }}
        />


        <Grid item xs={12}>

          {replys.length > 0 ?
            <>
              {sm ?
                <Paper
                  sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {data.role != 1 &&
                            <TableCell>応募者</TableCell>
                          }
                          <TableCell align="right">出荷量</TableCell>
                          <TableCell align="right">単価</TableCell>
                          <TableCell>コメント</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {replys.map((row) => (
                          <TableRow
                            key={row.v4}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          >
                            {data.role != 1 &&
                              <TableCell component="th" scope="row">
                                <Button href={"/user/" + row.user_v4}>{row.user_name}</Button>
                              </TableCell>
                            }
                            <TableCell align="right">{row.volume} kg</TableCell>
                            <TableCell align="right">{row.price}円/kg</TableCell>
                            <TableCell>{nl2br(row.comment)}</TableCell>
                            <TableCell>{nl2br(row.center_comment)}</TableCell>
                            <TableCell align="right">
                              {row.status == 0 &&
                                <>
                                  {data.role == 1 &&
                                    <Stack spacing={2} direction="row-reverse">
                                      <Button onClick={() => cancel(row.v4)} variant="outlined">取消</Button>
                                    </Stack>
                                  }
                                  {data.role == 2 &&
                                    <Stack spacing={2} direction="row-reverse">
                                      <Button onClick={() => apply(row.v4)} variant="contained">発注</Button>
                                      <Button onClick={() => reject(row.v4)} variant="outlined">却下</Button>
                                    </Stack>
                                  }
                                  {data.role == 3 &&
                                    <Stack spacing={2} direction="row-reverse">
                                      <Chip label="応募中" />
                                    </Stack>
                                  }
                                </>
                              }
                              {row.status == 1 &&
                                <>
                                  {data.role == 1 ?
                                    <Chip label="受注済" color="primary" />
                                    :
                                    <Chip label="発注済" color="primary" />
                                  }
                                </>
                              }
                              {row.status == 2 &&
                                <>
                                  {data.role == 1 ?
                                    <Chip label="却下済" />
                                    :
                                    <Chip label="却下済" />
                                  }
                                </>
                              }
                              {row.status == 3 &&
                                <>
                                  {data.role == 1 ?
                                    <Chip label="取消済" />
                                    :
                                    <Chip label="取消済" />
                                  }
                                </>
                              }
                              {row.status == 9 &&
                                <>
                                  {data.role == 1 ?
                                    <Chip label="納品完了" />
                                    :
                                    <Chip label="納品完了" />
                                  }
                                </>
                              }
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
                :
                <>
                  {replys.map((row) => (
                    <TableContainer component={Paper} sx={{ mt: 1 }} key={row.v4}>
                      <Table size="small" style={{ tableLayout: "fixed" }}>
                        <TableBody>
                          {data.role != 1 &&
                            <TableRow>
                              <TableCell component="th" scope="row" colSpan={2}>
                                <Button variant="outlined" href={"/user/" + row.user_v4}>{row.user_name}</Button>
                              </TableCell>
                            </TableRow>
                          }
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: "30%" }}>出荷量</TableCell>
                            <TableCell align="right">{row.volume} kg</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: "30%" }}>単価</TableCell>
                            <TableCell align="right">{row.price}円/kg</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: "30%" }}>コメント</TableCell>
                            <TableCell>{nl2br(row.comment)}</TableCell>
                          </TableRow>
                          {row.center_comment != '' &&
                            <TableRow>
                              <TableCell component="th" scope="row" style={{ width: "30%" }}></TableCell>
                              <TableCell>{nl2br(row.center_comment)}</TableCell>
                            </TableRow>
                          }
                          <TableRow>
                            <TableCell colSpan={2} align="right">
                              {row.status == 0 &&
                                <>
                                  {data.role == 1 &&
                                    <Stack spacing={2} direction="row-reverse">
                                      <Button onClick={() => cancel(row.v4)} variant="outlined">取消</Button>
                                    </Stack>
                                  }
                                  {data.role == 2 &&
                                    <Stack spacing={2} direction="row-reverse">
                                      <Button onClick={() => apply(row.v4)} variant="contained">発注</Button>
                                      <Button onClick={() => reject(row.v4)} variant="outlined">却下</Button>
                                    </Stack>
                                  }
                                  {data.role == 3 &&
                                    <Stack spacing={2} direction="row-reverse">
                                      <Chip label="応募中" />
                                    </Stack>
                                  }
                                </>
                              }
                              {row.status == 1 &&
                                <>
                                  {data.role == 1 ?
                                    <Chip label="受注済" color="primary" />
                                    :
                                    <Chip label="発注済" color="primary" />
                                  }
                                </>
                              }
                              {row.status == 2 &&
                                <>
                                  {data.role == 1 ?
                                    <Chip label="却下済" />
                                    :
                                    <Chip label="却下済" />
                                  }
                                </>
                              }
                              {row.status == 3 &&
                                <>
                                  {data.role == 1 ?
                                    <Chip label="取消済" />
                                    :
                                    <Chip label="取消済" />
                                  }
                                </>
                              }
                              {row.status == 9 &&
                                <>
                                  {data.role == 1 ?
                                    <Chip label="納品完了" />
                                    :
                                    <Chip label="納品完了" />
                                  }
                                </>
                              }
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))}
                </>
              }
            </>
            :
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Typography align='center'>
                応募はありません
              </Typography>
            </Paper>

          }

        </Grid>


        {
          data.role == 1 ?
            <Grid container spacing={2}>
              {volumeProps.value - fixed > 0 ?
                <>
                  <Grid item xs={6}>
                    <Button variant="outlined" onClick={back} sx={{ mt: 3 }} fullWidth>もどる</Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button variant="contained" onClick={submit} sx={{ mt: 3 }} fullWidth>応募</Button>
                  </Grid>
                </>
                :
                <Grid item xs={12}>
                  <Button variant="outlined" onClick={back} sx={{ mt: 3 }} fullWidth>もどる</Button>
                </Grid>
              }
            </Grid>
            :
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button variant="outlined" onClick={back} sx={{ mt: 3 }} fullWidth>もどる</Button>
              </Grid>
            </Grid>
        }

        <Dialog open={dopen} onClose={handleDClose}>
          <DialogTitle>応募</DialogTitle>
          <DialogContent>
            {/*
            <DialogContentText>
              To subscribe to this website, please enter your email address here. We
              will send updates occasionally.
            </DialogContentText>
            */}

            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: 220, mt: 3 }}>
                  <InputLabel htmlFor='request-volume'>出荷量</InputLabel>
                  <OutlinedInput
                    id="request-volume"
                    label="出荷量"
                    type="number"
                    inputProps={{ min: 1, max: volumeProps.value - fixed }}
                    {...dvolumeProps}
                    endAdornment={<InputAdornment position="end">kg</InputAdornment>}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: 220, mt: 3 }}>
                  <InputLabel htmlFor='request-volume'>単価</InputLabel>
                  <OutlinedInput
                    id="request-volume"
                    label="単価"
                    type="number"
                    inputProps={{ min: 1 }}
                    {...dpriceProps}
                    endAdornment={<InputAdornment position="end">円/kg</InputAdornment>}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12}>

              </Grid>
            </Grid>
            <TextField
              label="コメント"
              fullWidth
              multiline
              type="text"
              {...dcommentProps} sx={{ mt: 3 }}
            />

          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleDClose}>キャンセル</Button>
            <Button disabled={hasError} variant="contained" onClick={handleDSubmit}>応募</Button>
          </DialogActions>
        </Dialog>

        <ItemRequestDialog open={copen} dataProps={ccommentProps} title="取消" handleClose={cancelClose} handleSubmit={cancelSubmit} />

        <ItemRequestDialog open={aopen} dataProps={acommentProps} title="発注" handleClose={applyClose} handleSubmit={applySubmit} />

        <ItemRequestDialog open={ropen} dataProps={rcommentProps} title="却下" handleClose={rejectClose} handleSubmit={rejectSubmit} />

        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={open}
          onClose={handleClose}
          autoHideDuration={6000}
          message={msg}
        />


      </Paper >
    </Grid >
  );
}
