import { React } from "react";
import { Grid, Paper } from "@mui/material";
import Password from "./Password";

export default function Password0() {
    return (
      <Grid item xs={12}>
        <Paper
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Password />
          </Paper>
      </Grid>
    );
}
