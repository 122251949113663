import { React, useState, useEffect, useLayoutEffect } from "react";
import { Button, Grid, Paper, TextField, CircularProgress, Typography, Box, Stack, Snackbar, Alert } from "@mui/material";
import { useInput } from "./hooks";
import { useMyData } from "./MyProvider";
import FileDropzone from "./FileDropzone";


export default function Profile() {

  const { zip2addr, getUserData, updateUserData, postAvatorFile, setAvator } = useMyData();

  const [localData, setLocalData] = useState({});

  const [nameProps, resetName, setName] = useInput("");
  const [zipProps, resetZip, setZip] = useInput("","number");
  const [addressProps, resetAddress, setAddress] = useInput("");
  const [phoneProps, resetPhone, setPhone] = useInput("");
  const [commentProps, resetComment, setComment] = useInput("");
  const [avatorProps, resetAvator, setLocalAvator] = useInput("");
  const [v4, setV4] = useState("");
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);

  const [hasError, setHasError] = useState(true); // エラーチェック

  // エラーチェック
  useEffect(() => {
    let err = false;

    if (nameProps.value === "") err = true;

    setHasError(err);
  });

  useLayoutEffect(() => {
    getUserData(setDataAndLoading);
  }, []);

  const setDataAndLoading = (data) => {
    setLoading(false);
    setLocalData(data);

    setV4(data.v4);
    setName(data.display_name);
    setZip(data.zip);
    setAddress(data.address);
    setPhone(data.phone);
    setComment(data.comment);
    setLocalAvator(data.avator);

    setAvator(data.avator);

  }

  const submit = (e) => {

    updateUserData({
      display_name: nameProps.value,
      zip: zipProps.value,
      address: addressProps.value,
      phone: phoneProps.value,
      comment: commentProps.value,
      avator: avatorProps.value,
    }, submitted);

  }

  const submitted = (ret) => {
    setOpen(ret);

    getUserData(setDataAndLoading);

  }

  const setUploadedFile = (file) => {
    setLocalAvator(file.id);
  }

  const uploadFile = (file) => {
    postAvatorFile(file,setUploadedFile);
  }


  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const zipChange = (e) => {

    let v = e.target.value;
    v = v.replace(/[０-９]/g, s => String.fromCharCode(s.charCodeAt(0) - 65248)).replace(/\D/g, '');
    setZip(v);

    if(v.length==7) {
      zip2addr(setAddress,v);
    }
  }

  return (
    <>
      {loading ?
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 20,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack sx={{ alignItems: 'center' }}>
              <CircularProgress />
            </Stack>
          </Paper>
        </Grid>
        :
        <Grid item xs={12}>
            <Typography component='h2' variant='h4' align='center'>
              プロフィール編集
            </Typography>

          <Paper
            sx={{
              mt: 3,
              p: 2,
              display: 'flex',
              flexDirection: 'column',
            }}
          >

            <FileDropzone upload={uploadFile} src={avatorProps.value ? `${process.env.REACT_APP_API_ENDPOINT}user/avator/${v4}/${avatorProps.value}` : ''}/>


            <TextField label="表示名" type="text" {...nameProps} autoComplete="name" sx={{ mt: 3 }} />

            <TextField label="〒" type="text" autoComplete="postal-code" inputProps={{ maxLength: 7 }} {...zipProps} onChange={zipChange} sx={{ width: 220, mt: 3 }} />

            <TextField label="住所" type="text" {...addressProps} sx={{ mt: 3 }} />

            <TextField label="電話番号" type="text" autoComplete="tel-national" {...phoneProps} sx={{ mt: 3 }} />

            <TextField minRows={4} label="コメント" multiline {...commentProps} sx={{ mt: 3 }} />

            <Button variant="contained" disabled={hasError} onClick={submit} sx={{ mt: 3 }} >更新</Button>

            <Snackbar
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              open={open}
              onClose={handleClose}
              autoHideDuration={6000}
              message="更新しました。"
            />

          </Paper>
        </Grid>
      }
    </>
  );
}
