import { React, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useMyData } from "./MyProvider";

import './FileDropzone.scss';

export default function FileDropzone(props) {
  const { t, sm } = useMyData();

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    acceptedFiles.forEach(element => props.upload(element));
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()} className={isDragActive ? "fileDropzone dragActive" : "fileDropzone"}>
      <input {...getInputProps()} />
      {
        isDragActive ?
        <p className='dropArea'>{t('画像をこのエリアにドロップ')}</p> :
        <p>{t('画像アップロード')}
          {sm && <span sx={{display:{xs:"none",sm:"none"}}}>{t('(ドラッグアンドドロップ または クリック)')}</span>}
          {!sm && <span sx={{display:{xs:"inline",sm:"none"}}}>{t('(タップして画像選択)')}</span>}
        </p>
      }
      {props.src != "" ? <img src={props.src} /> : <></>}
    </div>
  )
}