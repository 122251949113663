import { React, useState, useEffect, useLayoutEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Paper, Grid, Typography, CircularProgress, Stack, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button } from "@mui/material";
import { useInput } from "./hooks";
import { useMyData } from "./MyProvider";
//import Grid from '@mui/material/Unstable_Grid2';



export default function ItemRequestList({ data }) {

  const { t, sm, getRequestList, formatDate } = useMyData();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [localData, setLocalData] = useState([]);


  // エラーチェック
  useLayoutEffect(() => {
    getRequestList(setRequestList);
  }, []);

  const setRequestList = (data) => {
    setLoading(false);
    setLocalData(data);
  }



  return (
    <>
      {loading ?
        <Grid item xs={12}>
          <Paper
            sx={{
              p: 20,
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Stack sx={{ alignItems: 'center' }}>
              <CircularProgress />
            </Stack>
          </Paper>
        </Grid>
        :
        <Grid item xs={12}>
          <Typography component='h2' variant='h4' align='center'>
            食材リクエストリスト
          </Typography>

          {localData.length > 0 ?
            <>
              {sm ?
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                  <Table>{/* Table for PC */}
                    <TableHead>
                      <TableRow>
                        {data.role != 2 &&
                          <TableCell></TableCell>
                        }
                        <TableCell>食材名</TableCell>
                        <TableCell>納期</TableCell>
                        <TableCell align="right">必要量</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {localData.map((row) => (
                        <TableRow
                          key={row.v4}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {data.role != 2 &&
                            <TableCell component="th" scope="row">
                              <Button href={"/user/" + row.center_v4}>{row.center}</Button>
                            </TableCell>
                          }
                          <TableCell component="th" scope="row">
                            {row.name}
                          </TableCell>
                          <TableCell>{formatDate(row.duedate)}</TableCell>
                          <TableCell align="right">{row.volume} kg</TableCell>
                          <TableCell align="right"><Button href={"/itemRequest/" + row.v4} variant="contained">詳細</Button></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                :
                <>
                  {localData.map((row) => (
                    <TableContainer component={Paper} sx={{ mt: 1 }} key={row.v4}>
                      <Table size="small" style={{ tableLayout: "fixed" }}>
                        <TableBody>
                          {data.role != 2 &&
                            <TableRow>
                              <TableCell colSpan={2}><Button variant="outlined" href={"/user/" + row.center_v4}>{row.center}</Button></TableCell>
                            </TableRow>
                          }
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: "30%" }}>食材名</TableCell>
                            <TableCell>{row.name}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: "30%" }}>納期</TableCell>
                            <TableCell>{formatDate(row.duedate)}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: "30%" }}>必要量</TableCell>
                            <TableCell>{row.volume} kg</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell component="th" scope="row" style={{ width: "30%" }}></TableCell>
                            <TableCell align="right"><Button href={"/itemRequest/" + row.v4} variant="contained">詳細</Button></TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ))}
                </>
              }
            </>
            :
            <Typography align='center' sx={{ mt: 3 }}>
              リクエストはありません
            </Typography>

          }
        </Grid>
      }
    </>
  );
}
